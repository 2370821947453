<template>
  <Loading v-if="loading"></Loading>
  <!-- <Maintenance></Maintenance> -->
  <div class="protected float fix flex wrap center vcenter" :class="{ 'show': protect }">
    <div class="content flex wrap center vcenter">
      <img v-lazy="'/images/icon.webp'" alt="آتیاکراد" loading="lazy">
      <p>محتوای محافظت شده...</p>
    </div>
    <div class="overlay blur"></div>
  </div>
  <RouterView v-if="!maintenance" :authenticated="authenticated" :time="time" :credits="credits"
    :memorandums="memorandums" :_reminders_="reminders" :status="status" :path="path" :excluded="excluded" @onloading="(status) => {
      this.loading = status
    }" />
  <notifications position="top left" :duration="6000" :pauseOnHover="true" />
  <vue3-confirm-dialog />
</template>

<script>
/* eslint-disable */
import RouterView from 'vue-router'
import Loading from './components/Loading.vue'
import Maintenance from './views/Maintenance.vue'

import { getMessaging, getToken, onMessage } from 'firebase/messaging'

export default {
  name: 'App',
  inject: ['base'],
  components: {
    Loading,
    Maintenance,
    RouterView
  },
  mounted() {
    if (this.inspect) {
      document.addEventListener('contextmenu', (e) => e.preventDefault())
      document.onkeydown = (e) => {
        if (e.key === 123)
          e.preventDefault()
        if (e.ctrlKey && e.shiftKey && e.key === 'I')
          e.preventDefault()
        if (e.ctrlKey && e.shiftKey && e.key === 'C')
          e.preventDefault()
        if (e.ctrlKey && e.shiftKey && e.key === 'J')
          e.preventDefault()
        if (e.ctrlKey && e.key === 'U')
          e.preventDefault()
      }
    }

    window.addEventListener('keyup', this.screenshot)

    const worker = new Worker('/app-worker.js')
    worker.onmessage = (event) => {
      const { action, data } = event.data
      switch (action) {
        case 'loadStyles':
          this.style(data)
          break

        case 'loadScript':
          window.RAYCHAT_TOKEN = "6b6772f6-78fb-49ae-990c-fe4b7a8ce4b9"
          const raychat = document.createElement('script')
          raychat.src = 'https://widget-react.raychat.io/install/widget.js'
          raychat.async = 1
          document.head.appendChild(raychat)

          const gtm = document.createElement('script')
          gtm.text = `
            (function(w,d,s,l,i){
              w[l]=w[l]||[];
              w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
              var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),
                  dl=l!='dataLayer'?'&l='+l:'';
              j.async=true;
              j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
              f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-W6GQXPJ3');
          `
          document.head.appendChild(gtm)

          const noscript = document.createElement('noscript')
          noscript.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W6GQXPJ3" height="0" width="0" style="display:none;visibility:hidden"></iframe>';
          document.body.appendChild(noscript)

          const gtag = document.createElement('script')
          gtag.src = 'https://www.googletagmanager.com/gtag/js?id=G-QJH4BVP3KJ'
          gtag.async = 1
          document.head.appendChild(gtag)

          const _gtag = document.createElement('script')
          _gtag.text = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-QJH4BVP3KJ');
          `
          document.head.appendChild(_gtag)

          const clarity = document.createElement('script')
          clarity.text = `
            (function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "pr4mow2f0l");
          `
          document.head.appendChild(clarity)
          break

        case 'showNotification':
          document.addEventListener('click', () => {
            if (Notification)
              Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                  const messaging = getMessaging()
                  onMessage(messaging, (payload) => {
                    this.base.Notification().toast(payload.notification.title
                      , payload.notification.body)
                  })
                  getToken(messaging, {
                    vapidKey: 'BDVPk8P67viQIfuucm2BfU8Qxigfnw9Sz4yg9vUMjrW1NV4PCbB6Mn5mQWnHwVaSxEb7taX-tB-hVodHOoXYGU4'
                  }).then((currentToken) => {
                    if (currentToken) {
                      this.$cookies.set('fcm', currentToken)
                      if (!this.authenticated && !localStorage.getItem('fcm'))
                        this.base.API().post('Service/Global/Register', {
                          entity_type: 'TOKEN',
                          data: [{
                            uid: '00000000-0000-0000-0000-000000000000',
                            sid: '00000000-0000-0000-0000-000000000000',
                            token: currentToken
                          }]
                        }, (response) => {
                          localStorage.setItem('fcm', '1')
                        })
                      this.fcm(currentToken)
                    }
                  }).catch((error) => { })
                }
              })
          })
          break
        case 'getItem':
          worker.postMessage({ action: 'returnItem', key: data.key, value: localStorage.getItem(data.key) })
          break
      }
    }
  },
  created() {
    this.init()
  },
  data() {
    return {
      identifier: '',
      authenticated: false,
      socket: null,
      time: 0,
      credits: 0,
      memorandums: [],
      reminders: [],
      status: 0,
      inspect: false,
      path: window.location.pathname.toLowerCase(),
      routes: ['/dashboard'],
      loading: false,
      protect: false,
      maintenance: false,
      identify: false
    }
  },
  watch: {
    $route(from, to) {
      this.path = window.location.pathname.toLowerCase()
    },
    authenticated(newAuthenticated, oldAuthenticated) {
      if (this.authenticated)
        this.socket.port.postMessage({
          type: 'init',
          cookies: {
            identifier: this.identifier
          },
        })
      else
        this.socket.port.postMessage({
          type: 'disconnect',
        })
    }
  },
  methods: {
    init() {
      this.authenticate(true)

      setInterval(() => this.authenticate(), 1000)

      if (window.location.pathname !== '/') {
        this.routing()
        this.base.Security().identify(this.routes)
      }

      this.capture()
    },
    shared() {
      if (!this.socket) {
        this.socket = new SharedWorker('/websocket-worker.js')
        this.socket.port.onmessage = (event) => {
          const { type, data } = event.data

          switch (type) {
            case 'connected':
              setTimeout(() => {
                this.time = Number(localStorage.getItem('time')) || 0
                this.credits = String(Number(this.$cookies.get('balance') || 0) - Number(this.$cookies.get('inprocess') || 0))
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

                this.status = localStorage.getItem('status') || '-1'
              }, 1000)
              break

            case 'message':
              let response = JSON.parse(data)
              if (!response?.isTrusted) {
                if (response.span) {
                  this.time = Number(response.span)
                  localStorage.setItem('time', this.time)
                }

                if (response.credits) {
                  this.credits = String(Number(response.credits.balance || 0) - Number(response.credits.inprocess || 0)).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  this.$cookies.set('balance', String(response.credits.balance || 0).replace(/\s/g, '').replace(/,/g, ''))
                  this.$cookies.set('inprocess', String(response.credits.inprocess || 0).replace(/\s/g, '').replace(/,/g, ''))
                }

                if (response.metas)
                  localStorage.setItem('metas', JSON.stringify([response.metas]))

                if (response.memorandums)
                  this.memorandums = response.memorandums

                if (response.reminders)
                  this.reminders = response.reminders

                if (response.message === 'logout') {
                  try {
                    this.socket?.close()
                  } catch (e) {
                  }
                  this.base.Security().logout()
                }

                if (response.categories && response.menus) {
                  this.modules = {
                    categories: response.categories,
                    menus: response.menus,
                    options: response.options,
                  }
                  localStorage.setItem('modules', JSON.stringify([this.modules]))
                }

                if (response.status) {
                  this.status = response.status === 'under_maintenance'
                    ? '2'
                    : '1'
                  localStorage.setItem('status', this.status)
                }
              }
              break

            case 'retryConnection':
              this.status = '0'
              localStorage.setItem('status', this.status)
              break
          }
        }
        this.socket.port.start()
      }
      this.socket.port.postMessage({
        type: 'init',
        cookies: {
          identifier: this.identifier
        },
      })
    },
    routing() {
      if (!this.authenticated && this.base.Util().validate(this.routes.filter(path => {
        return window.location.pathname.toLowerCase().includes(path)
      })))
        window.location.pathname = '/Authentication'
      else if (this.authenticated && (this.path === '/authentication' || this.path === '/register'))
        window.location.pathname = '/Dashboard'
    },
    authenticate(init) {
      this.identifier = this.$cookies.get('identifier')
      this.authenticated = this.base.Util().validate(this.identifier)

      this.base.Security().identify(this.routes)

      if (init)
        this.shared()
    },
    style(data) {
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.href = data.href
      document.head.appendChild(link)

      link.onload = () => {
        document.fonts.ready.then(() => {
          if (data.cache)
            localStorage.setItem('fonts', Date.now())
        })
      }

      link.onerror = () => this.style(data)
    },
    fcm(token) {
      if (this.base.Util().validate(token)) {
        if (this.path.includes('/dashboard'))
          this.socket.port.postMessage({
            type: 'send',
            data: `FCMToken->${token}`
          })
      }
      else
        setTimeout(() => this.fcm(token), 1500)
    },
    capture() {
      const forbiddenTools = ['OBS', 'Screen Recorder', 'Snipping Tool']
      const userAgent = navigator.userAgent;
      forbiddenTools.forEach((tool) => {
        if (userAgent.includes(tool)) {
          alert('Screen capture tools are not allowed.')
        }
      })
    },
    screenshot(event) {
      if (event.key === 'PrintScreen') {
        this.protect = true
        setTimeout(() => this.protect = false, 5000)
      }
    }
  }
}
</script>

<style src="../src/assets/css/main.css" />
<style lang="less" src="../src/assets/css/main.less" />
<style src="vue3-carousel-3d/dist/index.css"></style>
<style src="../src/assets/css/views/shared.css" />
<style src="vue-step-progress/dist/main.css" />
<style src="../node_modules/vue3-confirm-dialog/dist/style.css" />