/* eslint-disable */
import api from './api'
import notification from './notification'
import util from './util'
import security from './security'
import cookies from 'vue-cookies'

function submit(obj, res = (obj) => {}) {
  const body = {
    entity_type: obj.entity_type,
    temporaryid: obj.temporaryid
  }

  if (obj.properties.SESSION)
    body['session'] = security.session().data

  if (obj.meta.action === 'post' || obj.meta.action.includes('post')) {
    const pinfo = {
      pid: Number(obj.properties['PROCESS'] || '0') || 0,
      uuid: `${obj.entity_type}_${obj.id}`,
      action: obj.meta.action.includes('_') ?
        obj.meta.action.split('_')[1].toUpperCase() || '' : obj.meta.action
    }

    if (obj.comment)
      pinfo['comment'] = obj.comment

    if (obj.dynamic)
      Object.assign(body, {
        data: obj.data
      })
    else {
      let entity = {}
      entity['id'] = util.validate(obj.id) ? Number(obj.id) : 0
      obj.data.forEach((value) => entity[value.key] = value.value)
      body['data'] = [entity]
    }

    if (obj.id)
      body['pinfo'] = pinfo

    if (obj.meta.endpoint) {
      if (obj.meta.action === 'post' || obj.meta.action.includes('post'))
        api.post(obj.meta.endpoint, body, (response) => {
          if (util.success(response)) {
            if (!obj.properties['SUCCESS'] || obj.properties['SUCCESS'] === 'YES')
              notification.toast('نتیجه عملیات', 'ثبت داده‌ها با موفقیت انجام گردید!', 'success')
            if (!obj.properties['RELOAD'] || obj.properties['RELOAD'] === 'YES')
              setTimeout(() => location.reload(), 1000)
          } else
            notification.error({
              code: response.data.errorCode,
              referr: obj.entity_type,
              text: response.data.message
            })
          res(response.data)
        }, (error) => {
          res(error)
        })
    } else {
      if (util.validate(obj.id))
        api.put(obj.properties['PUT'], body, (response) => {
          if (util.success(response)) {
            if (!obj.properties['SUCCESS'] || obj.properties['SUCCESS'] === 'YES')
              notification.toast('نتیجه عملیات', 'تغییرات با موفقیت ذخیره گردید!', 'success')
            if (obj.properties['CLOSE'] === 'YES')
              setTimeout(() => window.close(), 2000)
            else if (!obj.properties['RELOAD'] || obj.properties['RELOAD'] === 'YES')
              setTimeout(() => location.reload(), 2500)
          } else
            notification.error({
              code: response.data.errorCode,
              referr: obj.entity_type,
              text: response.data.message
            })

          if (pinfo.pid > 0) {
            if (response.data.pstatus.status === 'Success') {
              notification.toast('نتیجه عملیات', 'ارجاع با موفقیت انجام گردید!', 'success')
              setTimeout(() => window.close(), 1500)
            } else if (response.data.pstatus.errorCode !== 4006)
              switch (response.data.pstatus.errorCode) {
                case 4004:
                  notification.toast('نتیجه عملیات', 'رویه جهت ارجاع یافت نشد!', 'error')
                  break;
                case 4007:
                  notification.toast('نتیجه عملیات', 'کارپوشه مقصد یافت نشد!', 'error')
                  break;
                case 4017:
                  notification.toast('نتیجه عملیات', 'تغییراتی جهت ارجاع یافت نشد!', 'error')
                  break;
                case 4003:
                  notification.toast('نتیجه عملیات', 'ارجاع به دلیل عدم دسترسی با خطا مواجه گردید!', 'error')
                  break;
                case 4016:
                  notification.toast('نتیجه عملیات', 'ارجاع به دلیل نقص شروط با خطا مواجه گردید!', 'error')
                  break;
                default:
                  notification.toast('نتیجه عملیات', 'ارجاع با خطا مواجه گردید!', 'error')
                  break;
              }
          }
          res(response.data)
        }, (error) => {
          res(error)
        })
      else
        api.post(obj.properties['POST'], body, (response) => {
          if (util.success(response)) {
            if (!obj.properties['SUCCESS'] || obj.properties['SUCCESS'] === 'YES')
              notification.toast('نتیجه عملیات', 'ثبت داده‌ها با موفقیت انجام گردید!', 'success')
            if (!obj.properties['RELOAD'] || obj.properties['RELOAD'] === 'YES')
              setTimeout(() => location.reload(), 1000)
          } else
            notification.error({
              code: response.data.errorCode,
              referr: obj.entity_type,
              text: response.data.message
            })
          res(response.data)
        }, (error) => {
          res(error)
        })
    }
  }
}

function receive(file) {
  api.download(file.source, (response) => {
    if (response.status === 200) {
      file.raw = response.data
      file.raw.type = file.type
      download(file)
    } else
      notification.error({
        code: 4004
      })
  })
}

function download(file) {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(new Blob([file.raw]))
  if ((file.raw.type.includes('image/') || file.raw.type.includes('/pdf')) && /Firefox/.test(navigator.userAgent))
    window.open(link.href)
  else {
    link.setAttribute('download', file.name)
    document.body.appendChild(link)
    link.click()
  }
}

function parse(extra) {
  let extras = {}
  if (extra && typeof extra === 'string')
    extra.split(';').forEach(data => {
      data = data.split(':')[1]
      if (data) {
        data = data.split(',')
        let key = data[0].split('=')[1]
        let value = data[1].split('=')[1]
        if (extras[key]) {
          if (Array.isArray(extras[key])) {
            let values = extras[key]
            values.push(value)
            extras[key] = values
          } else
            extras[key] = [extras[key], value]
        } else
          extras[key] = value
      }
    })
  return extras
}

function data(extras, type = 'dynamic', res = () => {}) {
  let options = []
  if (type === 'dynamic') {
    let data = parse(extras)['data']
    if (data) {
      api.get(`Data/Extra/Find?key=${data}`,
        (response) => {
          if (util.success(response)) {
            data = parse(response.data.data.content)
            if (data)
              Object.keys(data).forEach((element) => {
                options.push({
                  id: element,
                  text: data[element]
                })
              })
            res(options)
          }
        }, (error) => {

        })
    }
    return options
  } else {
    let data = parse(extras)
    Object.keys(data).forEach((element) => {
      options.push({
        id: element,
        text: data[element]
      })
    })
    res(options)
  }
}

function search() {
  params = {}
  new URLSearchParams(window.location.search).forEach((value, key) => {
    params[key] = value
  })
  return params
}

export default {
  submit,
  receive,
  download,
  parse,
  data,
  search
}
